import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.less'
import { FormGroup, FormControl, ButtonGroup, Button, Glyphicon } from 'react-bootstrap'
import EuGrading from '../../../../EuGrading'
import { FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { addItem } from '../../../../../../actions/cart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import SupplierPopover from "../../SupplierPopover";

export class Product extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: 4
    }
  }

  onCountChange(event) {
    this.setState({
      count: parseInt(event.target.value, 10)
    })
  }

  addToCart() {
    const { product, shop, cartItems } = this.props
    this.props.addToCart(product.id, this.state.count, shop, cartItems)
  }

  render() {
    const { product, shop, includeVat, category } = this.props
    const description = shop.showFullProductDescriptionOnSearch ? product.description : null
    let supplier
    if (!!shop.showSupplierInSearchResults) {
      const productSource = product.supplier.id ? product.supplier : product.location
      if(!productSource.hideIt)
        supplier = <SupplierPopover supplier={product.supplier} supplierNameComponent={productSource.name} />
    }

    const price = (
      <FormattedNumber
        style={`currency`}
        value={includeVat ? Math.ceil(product.price + product.vat) : Math.ceil(product.price)}
        currency="SEK"
        minimumFractionDigits={0}
        maximumFractionDigits={0}
      />
    )
    const bonus = product.bonus && product.bonus !== 0 ? <div className="bonus">product.bonus</div> : null
    let delivery, deliveryClass
    switch (product.estimatedDeliveryType) {
      case 0:
      case 5:
        deliveryClass = 'text-danger font-weight-bold'
        break
      case 1:
        deliveryClass = 'text-success font-weight-bold'
        break
      case 2:
        deliveryClass = 'text-info font-weight-bold'
        break
      case 3:
        deliveryClass = 'text-warning font-weight-bold'
        break
      default:
        deliveryClass = ''
    }
    if (shop.deliveryDisplayMode === 1) {
      delivery =  (
        <div className={`delivery-time-text ${deliveryClass}`}>
          {product.estimatedDeliveryTimeShort}
        </div>
      )
    } else {
      delivery = <FontAwesomeIcon className={deliveryClass} icon={faTruck} />
    }
    let stockAmount
    if (shop.showStockAmountsOnSearch) {
      if (shop.showActualStockAmounts)
        stockAmount = product.stock > 50 ? '50+ i lager' : `${product.stock} i lager`
      else {
        stockAmount = product.stock <= 0 ? 'Ej i lager' : (product.stock <= 10 ? 'Begränsat antal' : 'Finns i lager')
      }
      stockAmount = <div>{stockAmount}</div>
    }
    const productLinkUrl = `/${category.slug}/${product.id}/${product.slug}`
    return (
      <tr>
        <td className={styles.numeric}>
          <Link to={productLinkUrl}>
            {product.productId}
          </Link>
        </td>
        <td className="product-info">
          <Link to={productLinkUrl}>
            <span className="product-description">
              {product.brand.name} {product.model.name}
              <em>- {product.attrs.dimension}</em>
            </span>
            <EuGrading product={product}/>
            {description}
            {supplier}
          </Link>
        </td>
        <td className={styles.numeric}>
          {price}
          {bonus}
        </td>
        <td className={styles.numeric}>
          {delivery}
        </td>
        <td className={styles.numeric}>
          {stockAmount}
        </td>
        <td className="actions row--buy">
          <FormGroup className={styles.inlineForm}>
            <FormControl type="number"
              name="count"
              value={this.state.count}
              onChange={this.onCountChange.bind(this)}
              min="1"
              max="99" />
            <ButtonGroup>
              <Button type="submit" bsStyle="success" onClick={this.addToCart.bind(this)}>
                <Glyphicon glyph="shopping-cart" />
              </Button>
            </ButtonGroup>
          </FormGroup>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = state => {
  return {
    includeVat: state.price.includeVat,
    wheelBuilder: state.wheelBuilder,
    shop: state.shop,
    user: state.user.user,
    cartItems: state.cart.items,
    category: state.categories.activeCategory
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  addToCart: (itemId, count, shop, cartItems) => addItem(itemId, count, shop, cartItems)
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product)
