import React from 'react'
import { connect } from 'react-redux'
import ProductsList from './ProductsList'
import ProductsListCompact from './ProductsListCompact'

export const SearchResults = (props) => {
  if (props.shop.compactProductList)
    return (
      <ProductsListCompact
        shop={props.shop}
        products={props.products}
        rearProducts={props.rearProducts}
        pagination={props.pagination}
        selectedSortOption={props.selectedSortOption}
        onPageClick={props.onPageClick}
        onSortByClick={props.onSortByClick}
        isPair={props.isPair}
        sortBy={props.sortBy}
        isStaggeredFitment={props.isStaggeredFitment}
      />
    )

  return (
    <ProductsList
      shop={props.shop}
      products={props.products}
      rearProducts={props.rearProducts}
      pagination={props.pagination}
      count={props.count}
      selectedSortOption={props.selectedSortOption}
      category={props.category}
      onPageClick={props.onPageClick}
      onSortByClick={props.onSortByClick}
      initialSearchDone={props.initialSearchDone}
      isPair={props.isPair}
      sortBy={props.sortBy}
      isStaggeredFitment={props.isStaggeredFitment}
    />
  )
}

const mapStateToProps = (state) => {
  const searchState = state.search.resultsByCategory[state.categories.activeCategory.id]
  const { isStaggeredFitment, sortBy } = state.search.resultsByCategory.tyre // TODO: Rims, complete wheels...
  return {
    shop: state.shop,
    products: searchState.products,
    rearProducts: searchState.rearProducts,
    isPair: searchState.isPair,
    pagination: searchState.pagination,
    count: searchState.count,
    category: state.categories.activeCategory,
    initialSearchDone: searchState.initialSearchDone,
    isStaggeredFitment,
    sortBy
  }
}

export default connect(mapStateToProps)(SearchResults)
