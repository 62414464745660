import XRegExp from 'xregexp'

const fields = {
  deliveryOption:     '^[0123]{1}$', // 0=workshop, 1=customer
  customerType:       {
    isRequired: (form) => { return form.customerInfoRequired; },
    regex:      '^[12]{1}$', // 1=company, 2=person
  },
  customerName:       {
    isRequired: (form) => { return form.customerInfoRequired; },
    regex:      '^[\\p{L}\\d\\s]{3,}$',
  },
  customerIdNumber:   {
    isRequired: (form) => { return form.values['customerType'] === '1'; },
    regex:      '^\\+?[\\d-\\s]{6,}$',  // Orgnum or personal ID num
  },
  customerAddress1:   {
    isRequired: (form) => { return false },
    regex:      '^[\\p{L}\\d\\s]{3,}$',
  },
  customerAddress2:   {
    isRequired: (form) => { return false; },
    regex: '^[\\p{L}\\d\\s]*$',
  },
  customerPostalCode: {
    isRequired: (form) => { return form.customerInfoRequired; },
    regex:      '^[\\d\\s]{4,7}$',
  },
  customerCity:       {
    isRequired: (form) => { return form.customerInfoRequired; },
    regex: '^[\\p{L}\\d\\s]{2,}$',
  },
  customerCountry:    {
    isRequired: (form) => { return form.customerInfoRequired; },
    regex: '^([\\p{L}\\d\\s]{2})?$',
  },
  customerEmail:      {
    isRequired: (form) => { return form.customerInfoRequired; },
    regex: '^[\\p{L}\\d-_+.]{2,}@[\\p{L}\\d-_+.]{2,}\\.[\\p{L}\\d-_+.]{2,}$',
  },
  customerPhone:      {
    isRequired: (form) => { return form.customerInfoRequired; },
    regex: '^\\+?[\\d-\\s]{5,}$',
  },

  customerReference:  {
    isRequired: (form) => { return form.isRequiredCheckoutReference; },
    regex: '^[\\p{L}\\d.,;:!?\\-@#$%^&*()_+=\\[\\]{}<>\\/\'\\"|~`][\\p{L}\\d\\s.,;:!?\\-@#$%^&*()_+=\\[\\]{}<>\\/\'\\"|~`]*$',
  },

  deliveryName:       '^([\\p{L}\\d\\s]{3,})?$',
  deliveryAddress1:   '^([\\p{L}\\d\\s]{3,})?$',
  deliveryAddress2:   '^[\\p{L}\\d\\s]*$',       // Optional
  deliveryPostalCode: '^([\\d\\s-]{5,7})?$',
  deliveryCity:       '^([\\p{L}\\d\\s]{3,})?$',
  deliveryCountry:    '^([\\p{L}\\d\\s]{2})?$',

  licencePlateNum:    '^([\\w\\s?\\d]{2,})?$',   // Optional

  addTpms:            {
    isRequired: (form) => { return form.addTpmsRequired; },
    regex:      '^(0|1)$'
  }

}

const validateOrder = (form) => {
  const errors = {}
  Object.keys(fields).forEach(key => {
    let isRequired = false // can be overriden inside the regex
    let regex = fields[key]
    let value = (form.values[key] || '').toString()

    if (typeof(regex) === 'object') {
      isRequired = regex.isRequired(form)
      regex = regex.regex;
    }
    const isNotValidValue = value.length && !XRegExp.cache(regex, 'i').test(value)
    if ((isRequired && !value.length) || isNotValidValue)
      errors[key] = true
  })
  return errors;
}

export default validateOrder
