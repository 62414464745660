import { push } from 'react-router-redux'
import qs from 'query-string'

export const updateQueryParams = (currentQuery, newQueryParams, removeParams) => {
  const newQuery = qs.parse(currentQuery)
  if (removeParams)
    removeParams.forEach(param => delete newQuery[param])
  const params = Object.assign({}, newQuery, newQueryParams)
  return push({
    search: qs.stringify(params)
  })
}
