import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedNumber } from 'react-intl'
import { T } from 'components/components/i18n'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col, FormControl, Button, ButtonGroup, Glyphicon } from 'react-bootstrap'
import { addItem } from '../../../../../../actions/cart'
import { setRim } from '../../../../../../actions/wheelBuilder'
import styles from './styles.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { calculatePrice } from '../../../../../../helpers/price'
import ProductInfoPopover from '../../ProductInfoPopover'
import SupplierPopover from '../../SupplierPopover'
import ConditionPopover from '../../ConditionPopover'
import StockAmountMessage from '../../StockAmountMessage'
import DeliveryTime from '../../../../DeliveryTime'
import RimTypeLabel from '../../RimTypeLabel'
import CommentInput from 'components/components/Form/CommentInput'

export class RimProduct extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: this.props.initialCount,
      comment: ''
    }
  }

  onButtonClick() {
    const { product, shop, cartItems, isWheelBuilder, addToCart, setRim } = this.props
    if (!isWheelBuilder)
      addToCart(product.id, this.state.count, shop, cartItems, this.state.comment)
    else
      setRim(product, this.state.count, this.state.comment)
  }

  onCountChange(event) {
    this.setState({
      count: parseInt(event.target.value, 10)
    })
  }

  onCommentChange(event) {
    this.setState({
      comment: event.target.value
    })
  }

  render() {
    const { product, shop, includeVat, category, isWheelBuilder, cartItems, selectedNumberOfPcs, priceType, currency } = this.props
    const productLinkUrl = isWheelBuilder ? `/hjul/${category.slug}/${product.id}/${product.slug}` : `/${category.slug}/${product.id}/${product.slug}`
    const imageSrc = product.image && product.image.webshop_thumb ? product.image.webshop_thumb : '/images/default-product-thumbnail.png'

    let productDescription
    if (shop.showFullProductDescriptionOnSearch)
      productDescription = product.description

    let stockAmount

    if (!!shop.showStockAmountsOnSearch) {
      const stockAmountMessage = <StockAmountMessage stock={product.stock} showActualStockAmounts={shop.showActualStockAmounts} />
      stockAmount = (
        <Row>
          <Col xs={6} className={styles.propertyTitle}><T id="In stock"/>:</Col>
          <Col xs={6}>{stockAmountMessage}</Col>
        </Row>
      )
    }

    let priceValue
    if (priceType === 'consumer')
      priceValue = product.consumerPrice
    else
      priceValue = product.price
    const vatPercent = includeVat ? product.vatPercent : null
    priceValue = calculatePrice(priceValue, selectedNumberOfPcs, vatPercent)
    const price = (
      <FormattedNumber
        style={`currency`}
        value={priceValue}
        currency={currency}
        minimumFractionDigits={0}
        maximumFractionDigits={0}
      />
    )

    let supplier
    if (!!shop.showSupplierInSearchResults) {
      const productSource = product.supplier.id ? product.supplier : product.location
      if (!productSource.hideIt)
        supplier = (
          <Row>
            <Col xs={6} className={styles.propertyTitle}><T id="Supplier"/>:</Col>
            <Col xs={6}><SupplierPopover supplier={product.supplier} supplierNameComponent={productSource.name} /></Col>
          </Row>
        )
    }

    let delivery, deliveryClass
    switch(product.estimatedDeliveryType) {
      case 0:
      case 5:
        deliveryClass = 'text-danger font-weight-bold'
        break
      case 1:
        deliveryClass = 'text-success font-weight-bold'
        break
      case 2:
        deliveryClass = 'text-info font-weight-bold'
        break
      case 3:
        deliveryClass = 'text-warning font-weight-bold'
        break
      default:
        deliveryClass = ''
    }

    if(product.deliveryTime && !product.deliveryTime.hideIt) {
      if (shop.deliveryDisplayMode === 1 ) {
        let deliveryTime
        deliveryTime = <DeliveryTime deliveryInfo={product.deliveryTime} />
        delivery = <span className={deliveryClass}>{deliveryTime}</span>
      } else {
        delivery = (
          <FontAwesomeIcon
            className={deliveryClass}
            icon={faTruck}
          />
        )
      }
    }

    const accentedCellClass = `${styles.accentedCell} background-light`
    const itemsAlreadyInCart = cartItems.filter(item => item.id === product.id)
    let quantityInCartMessage = <br/>
    const sum = itemsAlreadyInCart.reduce((sum, item) => (sum + item.count), 0);
    if (sum > 0)
      quantityInCartMessage = <Link to="/kassa">{sum} <T id="pcs already in cart"/></Link>
    let button
    if (!shop.prohibitPurchase)
      button = (
        <ButtonGroup>
          <Button type="submit" className={styles.button} bsStyle="success" onClick={this.onButtonClick.bind(this)}>
            <Glyphicon glyph="shopping-cart" /> <T id="Buy" />
          </Button>
        </ButtonGroup>
      )
    const productTitle = [
      <Link to={productLinkUrl} className={`${styles.productTitle} text-colored`} key="product-name">
        {product.brand && product.brand.name} <em>{product.model && product.model.name}</em>
      </Link>,
      <div className={styles.summary} key="product-dimension">{product.attrs.dimension}</div>
    ]

    return (
      <div className={`${styles.product} rim-product`}>
        <div className={styles.content}>
          <Link to={productLinkUrl} className={styles.flexCenter}>
            <img src={imageSrc} alt=""/>
          </Link>
          <ProductInfoPopover product={product} productTitleElements={productTitle} />
          <div>{productDescription}</div>
          <div className={styles.comment}>{product.comment}</div>
          <div className={styles.characteristicsTitle}>Characteristics</div>
          <div className={styles.characteristicsSection}>
            <Row>
              <Col xs={6} className={styles.propertyTitle}>
                <T id="Width"/>:
              </Col>
              <Col xs={6}>
                {product.attrs.width}
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={styles.propertyTitle}>
                <T id="Diameter"/>:
              </Col>
              <Col xs={6}>
                {product.attrs.diameter}
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={styles.propertyTitle}>
                <T id="Bolt pattern"/>:
              </Col>
              <Col xs={6}>
                {product.attrs.boltPatterns.join(", ")}
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={styles.propertyTitle}>
                <T id="Et"/>:
              </Col>
              <Col xs={6}>
                {product.attrs.etOffset}
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={styles.propertyTitle}>
                <T id="Hub bore"/>:
              </Col>
              <Col xs={6}>
                {product.attrs.centerBore}
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={styles.propertyTitle}>
                <T id="Item"/>:
              </Col>
              <Col xs={6}>
                {product.productId}
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={styles.propertyTitle}>
                <T id="Type"/>:
              </Col>
              <Col xs={6}>
                <RimTypeLabel fullName rimType={product.attrs.rimType.id} />
              </Col>
            </Row>
            <Row>
              <Col xs={6} className={styles.propertyTitle}>
                <T id="Color"/>:
              </Col>
              <Col xs={6}>
                {product.attrs.color}
              </Col>
            </Row>
          </div>
          <div className={styles.characteristicsSection}>
            {stockAmount}
            <Row>
              <Col xs={6} className={styles.propertyTitle}>
                <T id="Delivery time"/>:
              </Col>
              <Col xs={6}>{' '}{delivery}</Col>
            </Row>
            {supplier}
            {product.condition && product.condition.type.toUpperCase() !== 'NEW' && (
              <Row>
                <Col xs={6} className={styles.propertyTitle}>
                  <T id="Condition"/>:
                </Col>
                <Col xs={6}>
                  <ConditionPopover condition={product.condition} />
                </Col>
              </Row>
            )}
          </div>
          <Row>
            <Col xs={12}>
              <T id="Note">
                {
                  msg => (
                    <CommentInput
                      placeholder={msg}
                      className={`${styles.commentInput} ${styles.marginBottom}`}
                      onChange={this.onCommentChange.bind(this)}
                    />
                  )
                }
              </T>
            </Col>
          </Row>
          <Row className={styles.quantityInCart}>
            <Col xs={12}>{quantityInCartMessage}</Col>
          </Row>
          <Row className="background-light"><Col xs={6} className={styles.accentedCell}><T id="Items"/>:</Col>
            <Col xs={6}>
              <FormControl type="number"
                name="count"
                value={this.state.count}
                onChange={this.onCountChange.bind(this)}
                min="1"
                className={styles.input}
                max="99"
              />
            </Col>
          </Row>
          {shop.limitPurchaseQuantityToStock && product.stock < parseInt(this.state.count, 10) && (
            <Row>
              <Col xs={12}>
                <span className={styles.limitedQuantityMessage}>
                  <T id="Limited quantity available" />
                </span>
              </Col>
            </Row>
          )}
          {product.bonus !== 0 && (
            <Row className="background-light">
              <Col xs={12}>
                  <FormattedNumber
                    style={`percent`}
                    value={product.bonus/100}
                    minimumFractionDigits={0}
                    maximumFractionDigits={0}
                  />&nbsp;<T id="bonus"/>
              </Col>
            </Row>
          )}
          <Row className={accentedCellClass}>
            <Col xs={6}><T id="Price"/>:</Col><Col xs={6}><strong className={styles.price}>{price}</strong></Col>
          </Row>
        </div>
        {button}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    includeVat: state.price.includeVat,
    selectedNumberOfPcs: state.price.selectedNumberOfPcs,
    priceType: state.price.priceType,
    shop: state.shop,
    cartItems: state.cart.items,
    category: state.categories.activeCategory,
    isWheelBuilder: state.wheelBuilder.showWheelProgress,
    currency: state.intl.currency,
    initialCount: state.shop.limitPurchaseQuantityToStock ? Math.min(props.product.stock, 4) : 4,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  addToCart: (itemId, count, shop, cartItems, comment) => addItem(itemId, count, shop, cartItems, comment),
  setRim: (product, count, comment) => setRim(product, count, comment)
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RimProduct)
