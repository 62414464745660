import React from 'react'
import { connect } from 'react-redux'
import styles from './styles.less'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { removeItem } from '../../../actions/cart'
import { FormattedNumber } from 'react-intl'
import { T } from 'components/components/i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import CATEGORIES from '../../../helpers/categories'
import classnames from 'classnames'

export const CartItem = (props) => {

    const { item, includeVat, cartItems, currency, inProgress, deleteDisabled } = props
    const price = includeVat ? item.formattedPriceInclVat : item.formattedPrice
    const imageSrc = item && item.image ? item.image.webshop_thumb : '/images/default-product-image.png'
    const deleteItem = () => {
      if (!item.deleteDisabled)
        props.removeItem(item, cartItems)
    }
    let title
    const vehicle = !!(item.car.vehicleId || item.car.licenseplate) && (
      <div>
        <T id="Vehicle" />:
        {item.car.licenseplate || item.car.vehicleId}
      </div>
    )
    if (item.category.id === CATEGORIES.COMPLETE_WHEEL.id) {
      title = (
        <div className={classnames('cart-item-description', styles.productDescription)}>
          <div className={styles.productDescription}>
            <strong className={classnames('text-overflow', styles.cartItemStrong)}><em>{item.tyre.brand.name} {item.tyre.model.name}</em></strong> {item.tyre.attrs.dimension}
          </div>
          <div className={classnames('cart-item-description', styles.productDescription)}>
            <strong className={classnames('text-overflow', styles.cartItemStrong)}>
              <em>{item.rim.brand.name} {item.rim.model.name}</em>
            </strong> {item.rim.attrs.dimension}
          </div>
          {vehicle}
        </div>
      )
    } else if (item.category.id === CATEGORIES.OTHER.id) {
      const productName = (item.autoProductId || !item.brand.name) ? item.name : `${item.brand.name} ${item.model.name || ''}`
      title = (
        <div className={classnames('cart-item-description', styles.productDescription)}>
          <strong className={classnames('text-overflow', styles.cartItemStrong)}><em>{productName}</em></strong> {item.description || ''}
          {vehicle}
        </div>
      )
    } else {
      const productName = item.autoProductId ? item.name : `${item.brand.name} ${item.model.name || ''}`
      title = (
        <div className={classnames('cart-item-description', styles.productDescription)}>
          <strong className={classnames('text-overflow', styles.cartItemStrong)}><em>{productName}</em></strong> {item.attrs.dimension}
          {vehicle}
        </div>
      )
    }
    const productInfo = [
      <div className={classnames(styles.title, 'title')}>
        <img src={imageSrc} alt='' className={styles.cartItemImg} />
        {title}
      </div>,
      <div className={styles.flexRowCenter}>
        <span className={classnames(styles.price, 'price')}>
          {item.count} x
          <FormattedNumber
            minimumFractionDigits={0}
            maximumFractionDigits={0}
            value={price}
            style={`currency`}
            currency={currency}/>
        </span>
      </div>
    ]

    return (
      <li className={classnames(styles.cartItem, 'cart-item')}>
        {!item.autoProductId && (
          <Link to={`/${item.category.slug}/${item.id}/${item.slug}`} className={styles.cartItemLink}>
            {productInfo}
          </Link>
        )}
        {item.autoProductId && (
          <span className={styles.cartItemLink}>{productInfo}</span>
        )}
        {inProgress && <FontAwesomeIcon icon={faSpinner} spin />}
        {!inProgress && !deleteDisabled && (
          <div id="delete" onClick={deleteItem} className={classnames('cart-item-remove', styles.cartItemRemove)}>
            <span className="glyphicon glyphicon-trash delete"></span>
          </div>
        )}
      </li>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({
  removeItem: (item, cartItems) => removeItem(item, cartItems)
}, dispatch)

const mapStateToProps = (state, props) => {
  return {
    includeVat: state.price.includeVat,
    currency: state.intl.currency,
    inProgress: state.cart.updateInProgress[props.item.id],
    deleteDisabled: props.item.autoProductId && props.item.autoProductType === 'MANDATORY',
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartItem)
