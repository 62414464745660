import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styles from './styles.less'
import { FormGroup, FormControl, ControlLabel, Tooltip, Popover, OverlayTrigger } from 'react-bootstrap'
import { keyCodes } from '../../../../../../constants/keyCodes'
import { T } from 'components/components/i18n'
import { initializeCombinedSearch } from '../../../../../../actions/combinedSearch'
import classnames from 'classnames'
import { resetVehicle } from '../../../../../../actions/vehicle'
import { SEARCH_MODES } from 'constants/searchModes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import CarInfo from '../../../CarInfo'
import { isMobile } from '../../../../../../helpers/general'

class Query extends React.Component {
  constructor(props) {
    super(props)
    this.handleKeyPress = this.onKeyPress.bind(this)
    this.handleChange = this.onChange.bind(this)
    this.props.initializeCombinedSearch()
  }

  onKeyPress(event) {
    if (event.which === keyCodes.ENTER)
      this.props.onEnterPress()
  }

  onChange(event) {
    if (event.target.value !== this.props.value) {
      this.props.resetVehicle();
    }
    this.props.onChange('query', event.target.value)
  }

  render() {
    const { value, selectedSearchMode, vehicle } = this.props
    const vehicleComment = vehicle && vehicle.comment ? (
      <div style={{marginTop: '10px', marginBottom: '10px', color: 'red'}}>
        {vehicle.comment}
      </div>
    ) : ''
    let vehicleInfo
    if (vehicle && vehicle.description) {
      vehicleInfo = (
        <div className={styles.vehicleInfo}>
          <OverlayTrigger
            trigger="click"
            rootClose
            placement={isMobile() ? 'bottom' : 'right'}
            overlay={<Popover className={styles.popoverCarInfo}><CarInfo /></Popover>}
          >
            <OverlayTrigger placement="top" overlay={<Tooltip id="carinfo-tooltip"><T id="Click to show more info" /></Tooltip>}>
              <button className={`${styles.link} carInfo_link button-link`}>
                {vehicle.description} <FontAwesomeIcon className={styles.carInfoIcon} icon={faInfoCircle} />
              </button>
            </OverlayTrigger>
          </OverlayTrigger>
          {vehicleComment}
        </div>
      )
    }

    let linkText = null
    let helpText = null
    // eslint-disable-next-line default-case
    switch (selectedSearchMode) {
      case SEARCH_MODES.combined:
        linkText = <T id="Search dimension/license plate number" />
        helpText = (
          <React.Fragment>
            #93013976 = sök art.nr.<br/>
            s:12345678 = sök lev.art.nr.<br />
            XXXXX = sök EAN kod <br />
            XXX999 = <T id="License plate number"/><br />
            245.45.20 = <T id="Dimension"/><br />
          </React.Fragment>
        )
        break
      case SEARCH_MODES.dimension:
        linkText = <T id="Search dimension" />
        helpText = (
          <React.Fragment>
            #93013976 = sök art.nr.<br/>
            s:12345678 = sök lev.art.nr.<br />
            XXXXX = sök EAN kod <br />
            245.45.20 = <T id="Dimension"/><br />
          </React.Fragment>
        )
        break
      case SEARCH_MODES.licensePlate:
        linkText = <T id="Search license plate number" />
        helpText = (
          <React.Fragment>
            #93013976 = sök art.nr.<br/>
            s:12345678 = sök lev.art.nr.<br />
            XXXXX = sök EAN kod <br />
            XXX999 = <T id="License plate number"/><br />
          </React.Fragment>
        )
        break
    }

    return (
      <div>
        <FormGroup className={classnames(styles.component, 'search-mode-query')}>
          <ControlLabel>
            {linkText}
          </ControlLabel>
          <div className={styles['input-wrapper']}>
            <FormControl
              name="query"
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
              className={`${styles.input} query_input`}
              defaultValue={value}
            />
            <OverlayTrigger
              placement={isMobile() ? 'top' : 'right'}
              overlay={
                <Popover>
                  <b><T id="Search tips" /></b>
                  <div className={styles['search-help-wrapper']}>
                    {helpText}
                  </div>
                </Popover>
              }
            >
              <button className={`${styles['search-help']} button-link`}>
                <FontAwesomeIcon className={styles.carInfoIcon} icon={faInfoCircle} />
              </button>
            </OverlayTrigger>
          </div>
        </FormGroup>
        {vehicleInfo}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { vehicle } = state.vehicle.vehicle ? state.vehicle : state.carPicker
  return {
    vehicle
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  initializeCombinedSearch: () => initializeCombinedSearch(),
  resetVehicle: () => resetVehicle()
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Query)
