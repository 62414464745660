// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__comment-input-wrapper___vQ41S {
  position: relative;
}
.styles__comment-input___R7qpD {
  padding-right: 26px !important;
}
.styles__comment-input-icon___hL7rV {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/components/Form/CommentInput/styles.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAEA;EACI,8BAAA;AAAJ;AAGA;EACI,kBAAA;EACA,WAAA;EACA,QAAA;EACA,eAAA;AADJ","sourcesContent":[".comment-input-wrapper {\n    position: relative;\n}\n\n.comment-input {\n    padding-right: 26px !important;\n}\n\n.comment-input-icon {\n    position: absolute;\n    right: 10px;\n    top: 6px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comment-input-wrapper": `styles__comment-input-wrapper___vQ41S`,
	"comment-input": `styles__comment-input___R7qpD`,
	"comment-input-icon": `styles__comment-input-icon___hL7rV`
};
export default ___CSS_LOADER_EXPORT___;
