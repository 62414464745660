import {
    SET_TYRE_SEARCH_PARAMETERS,
    SET_TYRE_SEARCH_PARAMETER,
    SEARCH_MODE_CHANGED,
    UPDATE_VEHICLE,
} from '../actions/search'
import { VEHICLE_RETRIEVED_BY_ID } from '../actions/carPicker'
import { RESET_VEHICLE } from '../actions/vehicle'
import { TYRE_TYPES } from '../constants/tyreTypes'
import { PREORDER_TARGET_ID_RETRIEVED, PREORDER_DATA_RETRIEVED } from '../actions/preOrder'
import { filteredTyreTypes, filteredSubTyreTypesFriction, filteredSubTyreTypesStud } from 'helpers/tyreTypes'

const searchParams = (state = {
  quality: [],
  vehicleType: 'alla',
  brandId: [],
  isRunflat: false,
  isEnforced: false,
  isSilence: false,
  isElectricVehicle: false,
  minQuantityInStock: '4',
  minimumTestScore: 0,
  query: '',
  rollingResistance: '',
  wetGrip: '',
  noiseEmissionDecibel: '',
  speedIndex: '',
  loadIndex: '',
  loadIndexRear: '',
  width: '',
  aspectRatio: '',
  diameter: '',
  isWinterOnly: false,
  axleType: 0,
  tyreType: [],
  carApprovalMark: '',
  vehicleId: '',
  searchMode: null,
  condition: [],
  tyreDimensions: [],
  comment: '',
}, action) => {
  switch (action.type) {
    case VEHICLE_RETRIEVED_BY_ID: {
      const { vehicle } = action
      const tyreDimensions = []
      if (vehicle && vehicle.tyreSizes) {
        const frontSize = vehicle.tyreSizes.find(size => size.deviationPercent === 0 && size.position === 'front')
        if (frontSize) {
          const { width, aspectRatio, diameter } = frontSize
          if (width && aspectRatio && diameter)
            tyreDimensions.push([width.toString(), aspectRatio.toString(), diameter.toString()])
        }

        const rearSize = vehicle.tyreSizes.find(size => size.deviationPercent === 0 && size.position === 'rear')
        if (rearSize) {
          const { width, aspectRatio, diameter } = rearSize
          if (width && aspectRatio && diameter)
            tyreDimensions.push([width.toString(), aspectRatio.toString(), diameter.toString()])
        }
      }
      return Object.assign({}, state, { vehicleId: action.vehicleId, tyreDimensions })
    }
    case SET_TYRE_SEARCH_PARAMETERS:
      return Object.assign({}, state, initializeSearchParams(action.parameters, action.currentSeason, action.shop))
    case SET_TYRE_SEARCH_PARAMETER:
      return Object.assign({}, state, action.params)

    case UPDATE_VEHICLE: {
      const { vehicle } = action
      const tyreDimensions = []
      if (vehicle && vehicle.tyreSizes) {
        const frontSize = vehicle.tyreSizes.find(size => size.deviationPercent === 0 && size.position === 'front')
        if (frontSize) {
          const { width, aspectRatio, diameter } = frontSize
          if (width && aspectRatio && diameter)
            tyreDimensions.push([width.toString(), aspectRatio.toString(), diameter.toString()])
        }

        const rearSize = vehicle.tyreSizes.find(size => size.deviationPercent === 0 && size.position === 'rear')
        if (rearSize) {
          const { width, aspectRatio, diameter } = rearSize
          if (width && aspectRatio && diameter)
            tyreDimensions.push([width.toString(), aspectRatio.toString(), diameter.toString()])
        }
      }
      return { ...state, tyreDimensions }
    }
    case RESET_VEHICLE:
      return { ...state, tyreDimensions: [] }

    case SEARCH_MODE_CHANGED:
      const changedParams = Object.assign({}, state)
      action.removedParams.forEach(param => delete changedParams[param])
      changedParams.searchMode = action.searchMode
      changedParams.tyreDimensions = []
      return changedParams
    case PREORDER_DATA_RETRIEVED:
    case PREORDER_TARGET_ID_RETRIEVED:
      if (action.targetId)
        return {...state, minQuantityInStock: 0}
      return state
    default:
      return state
  }
}

const initializeSearchParams = (params, currentSeason, shop) => {
  const {
    quality,
    vehicleType,
    brandId,
    isRunflat,
    isEnforced,
    isSilence,
    isElectricVehicle,
    minQuantityInStock,
    minimumTestScore,
    query,
    rollingResistance,
    wetGrip,
    noiseEmissionDecibel,
    speedIndex,
    loadIndex,
    loadIndexRear,
    isWinterOnly,
    axleType,
    tyreType,
    carApprovalMark,
    diameter,
    searchMode,
    vehicleId,
    condition,
    tyreDimensions,
    comment,
  } = params

  let selectedTyreTypes
  if (tyreType) {
    if (Array.isArray(tyreType))
      selectedTyreTypes = tyreType
    else
      selectedTyreTypes = [tyreType]

    // NOTE: This is to make sure even someone forced it from URI,
    //       it will still be filtered
    selectedTyreTypes = selectedTyreTypes.filter(
      tyreType => shop.enabledTyreTypes.includes(parseInt(tyreType, 10))
    )
  }

  if (!selectedTyreTypes || !selectedTyreTypes.length) {
    selectedTyreTypes = filteredTyreTypes(shop.enabledTyreTypes)
      .filter(key =>
        (
          TYRE_TYPES[key].season === currentSeason &&
          TYRE_TYPES[key].id !== TYRE_TYPES.WINTER.id
        )
      )
    if (currentSeason === 'winter') {
      // NOTE: Now in winter we have two set of subtypes 1 - friction, 2 - stud
      //       We only show one but depends on what is enabled in webshop settings
      const subTypesFriction = filteredSubTyreTypesFriction(shop.enabledTyreTypes)
      const subTypesStud = filteredSubTyreTypesStud(shop.enabledTyreTypes)
      // const defaultSubTypes = subTypesFriction.length
      //   ? subTypesFriction
      //   : subTypesStud
      const defaultSubTypes = [
        (
          subTypesFriction.length
            ? subTypesFriction
            : subTypesStud
        ).map(
          key => key
        )[0]
      ]
      selectedTyreTypes = defaultSubTypes
    }

    if (selectedTyreTypes.length)
      selectedTyreTypes = selectedTyreTypes.map(type => TYRE_TYPES[type].id)
    else if (currentSeason === 'summer' && shop.enabledTyreTypes.includes(parseInt(TYRE_TYPES.SUMMER.id, 10)))
      selectedTyreTypes = [TYRE_TYPES.SUMMER.id]
    else
      selectedTyreTypes = []
  }

  let selectedCondition
  if (condition) {
    if (Array.isArray(condition))
      selectedCondition = condition
    else
      selectedCondition = [condition]
  } else if (shop.condition && shop.condition.tyre) {
    selectedCondition = Object.keys(shop.condition.tyre).filter(key => shop.condition.tyre[key].default)
  }

  return {
    quality: quality ? (Array.isArray(quality) ? quality : [quality]) : [],
    vehicleType: vehicleType || 'alla',
    brandId: brandId ? (Array.isArray(brandId) ? brandId : [brandId]) : [],
    isRunflat: isRunflat === 'true',
    isEnforced: isEnforced === 'true',
    isSilence: isSilence === 'true',
    isElectricVehicle: isElectricVehicle === 'true',
    minQuantityInStock: minQuantityInStock || shop.minQuantityInStock || '4',
    minimumTestScore: parseInt(minimumTestScore, 10) || 0,
    query: query || '',
    rollingResistance: rollingResistance || '',
    wetGrip: wetGrip || '',
    noiseEmissionDecibel: noiseEmissionDecibel || '',
    speedIndex: speedIndex || '',
    loadIndex: loadIndex || '',
    loadIndexRear: loadIndexRear || '',
    isWinterOnly: isWinterOnly === 'true',
    axleType: parseInt(axleType, 10) || 0,
    tyreType: selectedTyreTypes,
    carApprovalMark: carApprovalMark || '',
    vehicleId: vehicleId || null,
    diameter: diameter || '',
    searchMode: !!searchMode ? parseInt(searchMode, 10) : shop.defaultSearchModeTyres,
    condition: selectedCondition || [],
    tyreDimensions: tyreDimensions || [],
    comment: comment || '',
  }
}

export default searchParams
