import React from 'react'
import { T } from 'components/components/i18n'
import Product from './Product'
import RimProduct from './RimProduct'
import Pagination from '../Pagination'
import SortBy from '../SortBy'
import PriceOptions from '../PriceOptions'
import CATEGORIES from '../../../../../helpers/categories'
import styles from './styles.less'
import CompleteWheelProduct from './CompleteWheelProduct'
import { Row, Col } from 'react-bootstrap'

export const ProductsList = (props) => {
  const {
    onSortByClick,
    onPageClick,
    selectedSortOption,
    shop,
    pagination,
    products,
    isPair,
    category,
    sortBy,
    initialSearchDone
  } = props
  const [visibleItems, setVisibleItems] = React.useState(8)
  const loadMoreRef = React.useRef(null)
  const hasFirstProduct = products[0] && products[0].id

  React.useEffect(() => {
    const _loadMoreRef = loadMoreRef.current
    if (isPair && window.IntersectionObserver) {
      setVisibleItems(8)
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setVisibleItems((prevVisibleItems) => prevVisibleItems + 8)
        }
      }, {
        threshold: 1.0
      })

      if (_loadMoreRef) {
        observer.observe(_loadMoreRef)
      }

      return () => {
        if (_loadMoreRef) {
          observer.unobserve(_loadMoreRef)
        }
      }
    }
  }, [isPair, products.length, sortBy, loadMoreRef, hasFirstProduct])

  let productComponents, paginationComponent, resultsHeader
  if (!initialSearchDone)
    return null
  if (initialSearchDone && products.length === 0) {
    productComponents = <h4 className={styles.noResults}><T id="Hittade inga produkter för din sökning" /></h4>
  } else {
    let className, productColumns
    productComponents = []
    switch(category.id) {
      case CATEGORIES.RIM.id:
        productComponents = products.map(product => <RimProduct product={product} key={product.id}/>)
        className = styles.flexWrap
        productColumns = styles.threeColumns
        break
      case CATEGORIES.COMPLETE_WHEEL.id:
        productComponents = products.map(product => <CompleteWheelProduct product={product} key={product.id} />)
        productColumns = styles.threeColumns
        break
      default:
        if (isPair && window.IntersectionObserver) {
          productComponents = products.slice(0, visibleItems).map(product => <Product product={product} isPair={isPair} key={product.id}/>)
        } else {
          productComponents = products.map(product => <Product product={product} isPair={isPair} key={product.id}/>)
        }

        if (isPair) {
          className = styles.flexWrap
          productColumns = styles.twoColumns
        } else {
          className = styles.flexWrapFourColumns
          productColumns = styles.fourColumns
        }
    }

    productComponents = (
      <ul
        className={`list-unstyled ${className} ${productColumns}`}
        key={`${products.length}-${sortBy}-${products[0] && products[0].id}-${products[products.length - 1] && products[products.length - 1].id}`}
      >
        {productComponents}
      </ul>
    )
    paginationComponent = isPair ? null : (
      <Pagination
        pagination={pagination}
        shop={shop}
        onPageClick={onPageClick}
      />
    )
    resultsHeader = (
      <Row className={styles.productsListHeader}>
        <Col md={3} sm={12} xs={12}><SortBy onSortByClick={onSortByClick} selectedSortOption={selectedSortOption}/></Col>
        <Col md={9} sm={12} xs={12} className={`${styles.priceOptions} productList_priceOptions`}><PriceOptions /></Col>
      </Row>
    )
  }

  return (
    <div className="products-list">
      {resultsHeader}
      {productComponents}
      {(isPair && visibleItems < products.length) && (
        <div className={styles.loadMore} ref={loadMoreRef}>
          <T id="Loading more..." />
        </div>
      )}
      {paginationComponent}
    </div>
  )
}

export default ProductsList
