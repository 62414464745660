import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedNumber } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormControl, Glyphicon } from 'react-bootstrap'
import { T } from 'components/components/i18n'
import { has as _has } from 'lodash'
import { addItem, addItemsGroup } from '../../../../../../actions/cart'
import { setTyre, setTyreGroup } from '../../../../../../actions/wheelBuilder'
import { CATEGORIES } from '../../../../../../helpers/categories'
import EuGrading from '../../../../EuGrading/new'
import styles from './styles.less'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import Button from '../../../../Button'
import TyreTypeIcon from '../../TyreTypeIcon'
import TestResults from '../../TestResults'
import ProductProperties from '../../ProductProperties'
import { calculatePrice } from '../../../../../../helpers/price'
import ProductInfoPopover from '../../ProductInfoPopover'
import SupplierPopover from '../../SupplierPopover'
import StockAmountMessage from '../../StockAmountMessage'
import DeliveryTime from '../../../../DeliveryTime'
import { isShowBadge } from 'helpers/badge'
import Image from 'components/components/Image'
import CommentInput from 'components/components/Form/CommentInput'

export class Product extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      count: this.props.initialCount,
      comment: '',
      countRear: this.props.initialCount,
      commentRear: '',
    }
  }

  onButtonClick() {
    const {
      product,
      shop,
      cartItems,
      isWheelBuilder,
      addToCart,
      addGroupToCart,
      setTyre,
      setTyreGroup,
      category,
      isStaggeredFitment
    } = this.props
    if (!isWheelBuilder) {
      if (isStaggeredFitment) {
        addGroupToCart([
          {
            itemId: product.id,
            count: this.state.count,
            comment: this.state.comment,
          },
          {
            itemId: product.rear.id,
            count: this.state.countRear,
            comment: this.state.commentRear,
          }
        ], shop)
      } else {
        addToCart(product.id, this.state.count, shop, cartItems, this.state.comment)
      }
    } else if (category.id === CATEGORIES.TYRE.id) {
      if (isStaggeredFitment) {
        product.count = this.state.count
        product.comment = this.state.comment
        product.rear.count = this.state.countRear
        product.rear.comment = this.state.commecommentRearnt
        setTyreGroup([
          product, product.rear
        ])
      } else {
        setTyre(product, this.state.count, this.state.comment)
      }
    }
  }

  onCountChange(event) {
    this.setState({
      count: parseInt(event.target.value, 10)
    })
  }

  onCommentChange(event) {
    this.setState({
      comment: event.target.value
    })
  }

  onCountRearChange(event) {
    this.setState({
      countRear: parseInt(event.target.value, 10)
    })
  }

  onCommentRearChange(event) {
    this.setState({
      commentRear: event.target.value
    })
  }

  render() {
    const {
      product,
      shop,
      inProgress,
      isPair,
      isStaggeredFitment
    } = this.props

    let button
    if (!shop.prohibitPurchase)
      button = (
        <Button
          className={`${styles.buttonPair} productList_buyButton`}
          onClick={this.onButtonClick.bind(this)}
          icon={<Glyphicon glyph="shopping-cart" />}
          label={<T id="Buy" />}
          inProgress={inProgress}
        />
      )

    const listingStyle = isStaggeredFitment ? styles.productPair : styles.productSingle
    const orderInfoSingle = isStaggeredFitment ? '' : styles.orderInfoItemSingle
    const extraStyle = ((isPair && !isStaggeredFitment) || !isPair) ? 'staggered-single' : 'staggered-pair'
    return (
      <li className={`${styles.product} ${listingStyle} ${extraStyle} product productList_productItem`}>
        <div className={`${styles.productItemWrapper} productList_productItemWrapper`}>
          <div className={`${styles.productItem} productItem`}>
            {!!isStaggeredFitment && (
              <span className={`background text-colored ${styles.productPositionStaggered} ${styles.productPositionFront}`}>
                <span><T id="Front" /></span>
              </span>
            )}
            {(!isStaggeredFitment && !!isPair) && (
              <div className={`${styles.productPosition}`}>
                <span className={`background text-colored ${styles.productPositionFront}`}>
                  <span><T id="Front" /></span>
                </span>
                <span className={`background text-colored ${styles.productPositionRear}`}>
                  <span><T id="Rear" /></span>
                </span>
              </div>
            )}
            <ProductDetails
              {...this.props}
              product={product}
              count={this.state.count}
              key={product._key}
            />
          </div>
          {!!isStaggeredFitment && (
            <div className={`${styles.productItem} productItem`}>
              <span className={`background text-colored ${styles.productPositionStaggered} ${styles.productPositionRear}`}>
                <span><T id="Rear" /></span>
              </span>
              <ProductDetails
                {...this.props}
                product={product.rear}
                count={this.state.countRear}
                key={`${product.id}-${product.rear.id}`}
              />
            </div>
          )}
        </div>
        <div className={`${styles.orderInfo} orderInfo`}>
          <div className={`${styles.orderInfoItem} ${orderInfoSingle}`}>
            <div>
              <span className={styles.label}><T id="Note" /></span>
              <span className={styles.input}>
                <T id="Note">
                  {
                    msg =>  (
                      <CommentInput
                        placeholder={msg}
                        className={`${styles.commentInput}`}
                        onChange={this.onCommentChange.bind(this)}
                      />
                    )
                  }
                </T>
              </span>
            </div>
            <div>
              <span className={styles.label}><T id="Items"/></span>
              <span className={styles.input}>
                <FormControl
                  type="number"
                  className={styles.commentInput}
                  name="count"
                  value={this.state.count}
                  onChange={this.onCountChange.bind(this)}
                  min="1"
                  max="99"
                />
              </span>
            </div>
          </div>
          {!!isStaggeredFitment && (
            <div className={styles.orderInfoItem}>
              <div>
                <span className={styles.label}><T id="Note" /></span>
                <span className={styles.input}>
                  <T id="Note">
                    {
                      msg =>  (
                        <CommentInput
                          placeholder={msg}
                          className={`${styles.commentInput}`}
                          onChange={this.onCommentRearChange.bind(this)}
                        />
                      )
                    }
                  </T>
                </span>
              </div>
              <div>
                <span className={styles.label}><T id="Items"/></span>
                <span className={styles.input}>
                  <FormControl
                    type="number"
                    className={styles.commentInput}
                    name="count"
                    value={this.state.countRear}
                    onChange={this.onCountRearChange.bind(this)}
                    min="1"
                    max="99"
                  />
                </span>
              </div>
            </div>
          )}
        </div>
        {button}
      </li>
    )
  }
}

class ProductDetails extends React.Component {
  render() {
    const {
      product,
      shop,
      includeVat,
      category,
      isWheelBuilder,
      cartItems,
      selectedNumberOfPcs,
      priceType,
      currency,
      selectedRim,
      count,
      isStaggeredFitment,
    } = this.props
    const productLinkUrl = isWheelBuilder ? `/hjul/${category.slug}/${product.id}/${product.slug}` : `/${category.slug}/${product.id}/${product.slug}`
    const imageSrc = product.image && product.image.webshop_thumb
      ? product.image.webshop_thumb
      : '/images/default-product-thumbnail.png'
      // 'https://api.eontyre.com/images/1127062/webshop_thumb.jpg'
      // '/images/default-product-thumbnail.png'

    const showBadge = isShowBadge(
      (_has(product, 'name') && product.name) || '',
      (_has(product, 'brand.name') && product.brand.name) || '',
      _has(shop, 'siteId') && shop.siteId
    )

    let productDescription
    if (shop.showFullProductDescriptionOnSearch)
      productDescription = product.description

    let stockAmount
    if (!!shop.showStockAmountsOnSearch) {
      const stockAmountMessage = <StockAmountMessage stock={product.stock} showActualStockAmounts={shop.showActualStockAmounts} />
      stockAmount = <div>{stockAmountMessage}</div>
    }
    let priceValue
    if (priceType === 'consumer')
      priceValue = product.consumerPrice
    else if (priceType === 'manufacturer')
      priceValue = product.manufacturerListPrice
    else
      priceValue = product.price
    const vatPercent = includeVat ? product.vatPercent : null
    priceValue = calculatePrice(priceValue, selectedNumberOfPcs, vatPercent)
    const price = (
      <FormattedNumber
        style={`currency`}
        value={priceValue}
        currency={currency}
        minimumFractionDigits={0}
        maximumFractionDigits={0}
      />
    )

    let supplier
    if (!!shop.showSupplierInSearchResults || isStaggeredFitment) {
      const productSource = product.supplier.id ? product.supplier : product.location
      if(!productSource.hideIt)
        supplier = <SupplierPopover supplier={product.supplier} supplierNameComponent={productSource.name} />
    }

    let delivery, deliveryClass
    switch(product.estimatedDeliveryType) {
       case 0:
       case 5:
         deliveryClass = 'text-danger font-weight-bold'
         break
       case 1:
         deliveryClass = 'text-success font-weight-bold'
         break
       case 2:
         deliveryClass = 'text-info font-weight-bold'
         break
       case 3:
         deliveryClass = 'text-warning font-weight-bold'
         break
       default:
         deliveryClass = ''
    }
    if (product.deliveryTime && !product.deliveryTime.hideIt) {
      if (shop.deliveryDisplayMode === 1) {
        let deliveryTime
        deliveryTime = <DeliveryTime deliveryInfo={product.deliveryTime} />
        delivery = <div className={deliveryClass}>{deliveryTime}</div>
      } else {
        delivery = (
          <FontAwesomeIcon
            className={deliveryClass}
            icon={faTruck}
          />
        )
      }
    }

    let euGrading, type, testResults
    euGrading = <EuGrading product={product} />
    type = <TyreTypeIcon product={product}/>
    testResults = <TestResults product={product}/>

    let bonus
    if (product.bonus !== 0)
      bonus = (
        <div>
          <FormattedNumber
            style={`percent`}
            value={product.bonus/100}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />&nbsp;<T id="bonus"/>
        </div>
      )
    const itemsAlreadyInCart = cartItems.filter(item => item.id === product.id)
    let quantityInCartMessage
    const sum = itemsAlreadyInCart.reduce((sum, item) => (sum + item.count), 0);
    if (sum > 0)
      quantityInCartMessage = <Link to="/kassa">{sum} <T id="pcs already in cart"/></Link>

    let wheelPrice
    if (isWheelBuilder && selectedRim) {
      let rimPriceValue
      if (priceType === 'consumer')
        rimPriceValue = selectedRim.consumerPrice
      else if (priceType === 'manufacturer')
        rimPriceValue = selectedRim.manufacturerListPrice
      else
        rimPriceValue = selectedRim.price

      rimPriceValue = calculatePrice(rimPriceValue, selectedNumberOfPcs, vatPercent);
      wheelPrice = (
        <div class="complete-wheel-price">
          <T id="Wheel price" />&nbsp;
          ({selectedNumberOfPcs} <T id="pcs" />):&nbsp;
          <FormattedNumber
            style={`currency`}
            value={rimPriceValue + priceValue}
            currency={currency}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />
        </div>
      )
    }

    const productTitle = [
      <div className={styles.productTitleWrapper}>
        <Link
          to={productLinkUrl}
          className={`${styles.productTitle} ${styles.productTitlePair} text-colored`}
          key="product-name"
          title={`${product.brand && product.brand.name} ${product.model && product.model.name}`}
        >
          <span>
            <span>{product.brand && product.brand.name}</span>
          </span>
          <span>
            <em>{product.model && product.model.name}</em>
          </span>
        </Link>
        <div className={`${styles.flexIt} ${styles.productDimensions}`} key="product-description">
          {product.attrs && product.attrs.dimension} {product.attrs && product.attrs.treadDepths && `(${product.attrs.treadDepths})`}
        </div>
      </div>
    ]

    return (
      <React.Fragment>
        <div className={styles.productMainInfoWrapper}>
          <Link to={productLinkUrl} className={styles.productThumbnail}>
            <Image src={imageSrc} width="120px" height="120px" />
            <div className={styles.typeBadge}>{type}</div>
            {showBadge && <img className={styles.productBadge} src={`/images/badge/michelin.png`} width="60px" alt="Michelin" />}
          </Link>
          <ProductInfoPopover product={product} productTitleElements={productTitle} />
          <div className={styles.topItem}>{euGrading}</div>
          <div className={`${styles.productProperties} productProperties`}>
            <ProductProperties product={product} condition={product.condition}/>
          </div>
        </div>
        <div className={`${styles.productDetailsWrapper}`}>
          <div className={styles.comment}>{product.comment}</div>
          <div className={styles.productDescription}>
            {bonus}
            {stockAmount}
            {delivery}
            {supplier}
            {testResults}
            {productDescription}
          </div>
          <div className={styles.quantityInCart}>
            {shop.limitPurchaseQuantityToStock && product.stock < parseInt(count, 10) && (
              <div className={styles.limitedQuantityMessage}>
                <T id="Limited quantity available" />
              </div>
            )}
            {quantityInCartMessage}
            {wheelPrice}
          </div>
          <div className={`${styles.priceInfo} productList_priceInfo`}>
            <strong className={`${styles.price} productList_price`}>
              {price} <span className={`${styles.pricePcs} productList_pricePcs`}>/ {selectedNumberOfPcs === 1 ? '' : selectedNumberOfPcs} <T id="pcs" /></span>
            </strong>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { isStaggeredFitment } = state.search.resultsByCategory.tyre // TODO: Rims, complete wheels...
  // NOTE: Even though the search parameters/criteria are staggered fitment, but
  //       some of the results might yield same product Id
  const _isStaggeredFitment = isStaggeredFitment && !props.product.isSameWithRear
  const maxCount = _isStaggeredFitment ? 2 : 4;
  return {
    includeVat: state.price.includeVat,
    selectedNumberOfPcs: state.price.selectedNumberOfPcs,
    priceType: state.price.priceType,
    shop: state.shop,
    user: state.user.user,
    cartItems: state.cart.items,
    category: state.categories.activeCategory,
    isWheelBuilder: state.wheelBuilder.showWheelProgress,
    selectedRim: state.wheelBuilder.rim,
    currency: state.intl.currency,
    inProgress: state.cart.updateInProgress[props.product.id],
    initialCount: state.shop.limitPurchaseQuantityToStock ? Math.min(props.product.stock, maxCount) : maxCount,
    isStaggeredFitment: _isStaggeredFitment,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  addToCart: (itemId, count, shop, cartItems, comment) => addItem(itemId, count, shop, cartItems, comment),
  addGroupToCart: (items, shop) => addItemsGroup(items, shop),
  setTyre: (product, count, comment) => setTyre(product, count, comment),
  setTyreGroup: (items) => setTyreGroup(items)
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product)
